import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {LocalStorageService} from './localstorage-service';
import {registerLocaleData} from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localePt from '@angular/common/locales/pt';
import {environment} from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SisBaseTranslateService {
    constructor(private storageService: LocalStorageService, private translateService: TranslateService) {
        this._locale = environment.localePadrao;
    }

    private _locale: string;

    get locale(): string {
        return this._locale || environment.localePadrao;
    }

    set locale(value: string) {
        this._locale = value;
    }

    use(lang: string): Observable<any> {
        this.storageService.store('idioma', lang);
        this.registerCulture(lang);
        return this.translateService.use(lang);
    }

    setDefaultLang(idioma: string) {
        this.registerCulture(idioma);
        return this.translateService.setDefaultLang(idioma);
    }

    get(key: string): Observable<string | any> {
        return this.translateService.get(key);
    }

    instant(key: string): string {
        return this.translateService.instant(key);
    }

    currentIdioma() {
        let idioma = this.storageService.getData('idioma');
        if (!idioma) {
            idioma = environment.idiomaPadrao;
        }
        return idioma;
    }

    currentLocale() {
        let locale = this.storageService.getData('locale');
        if (!locale) {
            locale = environment.localePadrao;
        }
        return locale;
    }

    registerCulture(idioma: string) {
        if (!idioma) {
            return;
        }

        // Registrar locale pra trabalhar com formatação numérica de forma correta
        switch (idioma) {
            case 'pt': {
                registerLocaleData(localePt, 'pt-BR');
                this._locale = 'pt-BR';
                break;
            }
            case 'en': {
                registerLocaleData(localeEn, 'en-US');
                this._locale = 'en-US';
                break;
            }
        }
        this.storageService.store('locale', this._locale);
    }
}
